<template>
  <div>
    <b-row class="header_row">
      <b-col>
        <h1>{{ $t('Actions Report') }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <h2>
          {{ $t('Actions for') }}:
          <b-link @click="show_modal = true">
            <b-icon-gear />
          </b-link>
          <a :href="build_link(true, true)">
            <b-icon-download />
          </a>
        </h2>
        <h3>{{ $t('Category') }}: {{ names('category') }}</h3>
        <h3>{{ $t('Line') }}: {{ names() }}</h3>

      </b-col>
    </b-row>
    <b-modal hide-header hide-footer v-model="show_modal">
      <actions-form @ok="go_to" />
    </b-modal>
    <b-overlay :show="loading">
      <b-row class="header_row">
        <b-col>
          <b-table-simple>
            <b-tr>
              <b-th>{{ $t('Line') }}</b-th>
              <b-th>{{ $t('HC') }}</b-th>
              <b-th>{{ $t('Question') }}</b-th>
              <b-th>{{ $t('Date') }}</b-th>
              <b-th>{{ $t('Score') }}</b-th>
              <b-th>{{ $t('Action Step') }}</b-th>
              <b-th>{{ $t('Deadline') }}</b-th>
              <b-th>{{ $t('Owner') }}</b-th>
            </b-tr>
            <b-tr v-for="(r, i) in formatted_resp" :key="i">
              <b-td>{{ r.info }}</b-td>
              <b-td>{{ r.category }}</b-td>
              <b-td>{{ translate(r.question__name) }}</b-td>
              <b-td>{{ r.date | date_format }}</b-td>
              <b-td>{{ r.p_score }} %</b-td>
              <b-td>{{ r.action }}</b-td>
              <b-td>{{ r.deadline | date_format }}</b-td>
              <b-td>{{ r.owner }}</b-td>
            </b-tr>
          </b-table-simple>

        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { date_format, dict_to_search, to_query } from "../shared";
import { merge } from 'lodash-es'
import ActionsForm from "../components/ActionsForm";
import { SERVER } from "../constants";


export default {
  name: "ActionsReport",
  components: { ActionsForm },
  filters: {
    date_format
  },
  mounted() {
    this.load()
    this.$watch(() => JSON.stringify(this.$route.query),
      () => this.load()
    )
  },
  computed: {
    lang() {
      return this.$store.getters.lang ?? 'en'
    },
    br_items() {
      return [{ text: this.$t('Home'), to: { name: 'Home' } },
      { text: this.$t('Reports'), to: { name: "Reports" } },
      { text: this.$t('Actions Report'), active: true }]
    },
    query_str() {
      return dict_to_search(this.$route.query)
    },
    formatted_resp() {
      return this.resp.map(a => merge(a, {
        category: this.readable_category(a.quiz__category),
        deadline: new Date(a.deadline),
        date: new Date(a.quiz__date),
        owner: this.readable_info(a.owner),
        info: this.readable_info(a.quiz__info),
        p_score: (parseFloat(a.quiz__p_score) * 100).toFixed(0)
      }))
    }
  },
  methods: {
    translate(line) {
      return line[this.lang]
    },
    build_link(xls = false, with_server = false) {
      let query_dict = merge({}, this.$route.query)
      if (xls) {
        query_dict['frmt'] = 'xlsx'
      }
      const query_str = dict_to_search(query_dict)
      let url = `api/reports/actions/?${query_str}`
      if (with_server) {
        url = `${SERVER}/${url}`
      }
      return url
    },
    names(type = 'lines') {
      const items = (this.$route.query[type] || []).map(i => parseInt(i))
      if (!items.length) {
        return this.$t('All')
      }
      let getter
      switch (type) {
        case 'lines':
          getter = this.$store.getters.additional_info
          break;
        case 'category':
          getter = this.$store.getters.category
          break;
        default:
          return ''
      }
      return getter.filter(o => items.indexOf(o.pk) != -1).map(o => o.name).join(', ')
    },
    go_to(args) {
      this.show_modal = false
      //develop-giraffe-test.lobby.obit.ru/sip/call/external
      this.$router.push({ name: this.$route.name, query: to_query(args) })
      // this.load()
    },
    load() {
      this.loading = true
      Promise.all([
        this.$store.dispatch(
          'get', {
          url: this.build_link()
        }
        ).then(r => this.resp = r),
        this.$store.dispatch('load_data', ['category', 'additional_info'])
      ]).then(
        () => this.loading = false
      )
    },
    readable_category(cat) {
      let ret = this.$store.getters.category.find(
        c => c.pk == cat
      )
      return ret ? ret.name : cat
    },
    readable_info(info) {
      let ret = this.$store.getters.additional_info.find(
        c => c.pk == parseInt(info)
      )
      return ret ? ret.name : info
    }
  },
  data() {
    return {
      loading: false,
      resp: [],
      show_modal: false
    }
  }
}
</script>

<style></style>
